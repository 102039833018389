<template>
  <!--  题库管理-->
  <div class="questionBank">
    <a-spin :indicator="indicator" :spinning="spinning">
      <div class="header">
        <div style="display: flex;">
          <div style="line-height: 40px;min-width: 50px">
            模块：
          </div>
          <div class="header-div">
          <span :class="examTypeId === item.id ? 'selectFont' : 'selectNoFont'" @click="headerClick(index, item.id)" ref="headerSpan" v-for="(item, index) in headerList" :key="item.id">
            {{item.name}}
          </span>
          </div>
        </div>
        <div style="height: 1px;border-bottom: 3px #F5F5F5 dashed;margin-left: -30px">

        </div>
        <div style="display: flex;margin-top: 10px">
          <div style="line-height: 40px;min-width: 50px">
            板块：
          </div>
          <div class="header-div">
        <span :class="boardCode === item.code ? 'selectFont' : 'selectNoFont'" @click="headerClick(index, item.code, 'mode')" ref="boardSpan" v-for="(item, index) in headerModeList" :key="item.code">
          {{item.name}}
        </span>
          </div>
        </div>

      </div>
      <div style="overflow: hidden;display: flex;justify-content: space-between;">
        <div class="menu_select">
          <div style="display: flex;justify-content: space-between;padding: 5px;width: 100%" >
            <a-button @click="()=>{this.typeAdd = true;typeForm.board = boardCode;typeForm.examTypeId = examTypeId}"  type="primary">新增</a-button>
            <a-button  @click="typeShow === false ? show() : hide()">修改</a-button>
          </div>
          <a-menu
              style="width: 100%;"
              mode="inline"
              v-model="menu"
              @click="leftChange"
          >
            <a-menu-item
                v-for="(item,index) in typeInfo"
                :key="item.id"
            ><div style="display: flex;justify-content: space-between">
              <a-tooltip>
                <template slot="title">
                  {{item.name}}
                </template>
                <span style="overflow: hidden;text-overflow:ellipsis; white-space: nowrap;">{{item.name}}</span>
              </a-tooltip>

              <a onClick="event.cancelBubble = true" @click="typeEditBtn(item)" v-show="typeShow" color="#87d068">
                修改
              </a>
            </div>
            </a-menu-item>
          </a-menu>
          <div style="display: flex;justify-content: right;padding: 5px">
            <a-pagination
                size="small"
                v-model="leftPagination.pageNo"
                :total="leftPagination.total"
                @change="pageChange"/>
          </div>
        </div>

        <div class="InfoTable">
          <div class="select_Info">
            <div>
              名称:  &nbsp;<a-input v-model="searchName" placeholder="输入名称搜索" style="width: 180px" size="default" @pressEnter="()=>this.searchOk()" />&nbsp;&nbsp;&nbsp;
              <a-button style="margin-left: 10px" type="primary" @click="searchOk" >查询</a-button>
              <a-button style="margin-left: 10px"  @click="searchReset">重置</a-button>
            </div>
          </div>
          <div style="min-height: 10px;background: #f0f2f5">

          </div>
          <div class="table_Info">
            <div style="display: flex;justify-content: space-between;margin-bottom: 10px">
              <div>
                <a-button type="primary" style="margin-bottom: 5px;margin-right: 10px" @click="()=>{this.addShow = true;this.getExamModel()}">新增普通题型</a-button>
                <a-button type="primary" style="margin-bottom: 5px" @click="()=>{this.addShow = true ; this.addType=true;this.getExamModel()}">新增复合题型</a-button>
              </div>
              <div>
                <a-button @click="loadBtns">下载模板</a-button>
                <a-button type="primary" @click="toImport" style="margin-left: 10px">导入数据</a-button>
                <input type="file" accept=".xlsx,.xls" ref="upFile" id="upFileId" @change="bulkImport" style="display:none">
              </div>
            </div>
            <a-table  :columns="columns"  :scroll="{ x: 1200,}" :data-source="InfoTable" rowKey="id"   :pagination="false"
            >
            <span slot="name" slot-scope="text,item,index">
              <a-tooltip>
              <template slot="title">
                {{item.name}}
              </template>
              <span style="overflow: hidden;-webkit-line-clamp: 2;text-overflow: ellipsis;display: -webkit-box;-webkit-box-orient: vertical;">
                {{item.name}}
              </span>
            </a-tooltip>
            </span>


              <!--            <span slot="code" slot-scope="text,item,index">-->
              <!--              <span v-for="list in item.questionCode">{{list + ';'}}</span>-->
              <!--            </span>-->
              <span slot="type" slot-scope="text,item,index">
              {{item.type === 'radio' ? '单选':'多选'}}
            </span>
              <span slot="stemId" slot-scope="text,item,index">
              {{item.stemId ? '复合题型':'普通题型'}}
            </span>
              <span slot="operate" slot-scope="text,item,index">
              <a style="margin-right: 10px" @click="editBtn(item.id)">修改</a>
              <a-popconfirm
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delTitle(item.id)"
                  @cancel="cancel"
              >
            <a >删除</a>
            </a-popconfirm>
            </span>
            </a-table>

            <div style="margin-top: 10px;align-items: center;display: flex;justify-content: space-between">
              <span style="font-size: medium;color: #929292">共 {{pagination.total}} 条记录 第 {{ pagination.current }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
              <a-pagination
                  :page-size-options="pagination.sizeOptions"
                  v-model="pagination.current"
                  :total="pagination.total"
                  show-size-changer
                  :page-size="pagination.pageSize"
                  @change="tableChange"
                  @showSizeChange="tableChange"
                  show-quick-jumper
                  style="float: right"
              >
              </a-pagination>
            </div>

          </div>
        </div>
      </div>

      <a-drawer
          :get-container="false"
          title="新增"
          width="50%"
          :visible="addShow"
          @close="addClose"
      >
        <a-form-model style="margin-bottom: 40px" :rules="rules" :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addItem" >
          <a-form-model-item label="类型" prop="type">
            <a-radio-group @change="()=>{
           if(addForm.type === 'radio'){
             this.addForm.able = '单选题'
           }else {
             this.addForm.able = '多选题'
           }
         }" v-model="addForm.type">
              <a-radio value="radio" >
                单选
              </a-radio>
              <a-radio value= "checkbox">
                多选
              </a-radio>
            </a-radio-group>
            <!--    _common:普通题型， _recombination：复合题型    -->
            <a @click="openSample(!addType ? 'questionBank_type_common':'questionBank_type_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </a-form-model-item>
          <a-form-model-item label="分类" >
            <!--          <a-select-->
            <!--              placeholder="选择分类"-->
            <!--              v-model="addForm.questionCode"-->
            <!--              mode="multiple"-->
            <!--              :maxTagCount= 3-->
            <!--              showArrow-->
            <!--              :maxTagTextLength = 7-->
            <!--          >-->
            <!--            <a-select-option v-for="item in typeInfoModel" :key="item.id" :value="item.code">-->
            <!--              {{item.name}}-->
            <!--            </a-select-option>-->
            <!--          </a-select>-->

            <a-select
                placeholder="选择分类"
                v-model="addForm.questionCode"
                mode="multiple"
                :maxTagCount= 3
                showArrow
                :maxTagTextLength = 7
                show-search
                option-filter-prop="children"
                @popupScroll="popupScroll_questionCode"
                @search="select_questionCode"
            ><a-select-option v-for="item in typeInfoModel" :key="item.id" :value="item.code">
              {{item.name}}
            </a-select-option></a-select>
          </a-form-model-item>
          <a-form-model-item label="题目名称" prop="name">
            <div style="display: flex">
              <a-input  v-model.trim="addForm.name"  placeholder="输入名称" @change="addTitle" />
              <a @click="openSample(!addType ? 'questionBank_name_common':'questionBank_name_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
            <ul  class="titleList" v-for="item in titleList">
              {{item.name}}
            </ul>
          </a-form-model-item>
          <a-form-model-item label="选项" prop="options">
            <div style="width: 100%;display: flex">
              <div style="width: 90%;">
                <div style="display: flex;align-items: center;"  v-for="(item,index) in addForm.options" :key="index">
                  <a-input placeholder="输入选项" style="width: 55%;margin-right: 10px" v-model="item.title" />
                  <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="addOptions" />
                  <a-icon type="minus-circle" v-if="index!==0" style="cursor: pointer;font-size: 18px;margin-right: 10px"   @click="delOptions(item,index)" />
                  <input  type="checkbox" v-model="item.answer" v-if="addForm.type==='checkbox'" :id="'check'+index" @change="resultKey(item,index)">
                  <input  type="radio" name="radio"  v-if="addForm.type==='radio'" :id="'check'+index" @change="resultRadio(item,index)">
                  正确答案
                  <a-input-number v-show="addType" v-if="item.answer===1 " v-model="item.socore" style="margin-left: 5px;flex: 1" placeholder="请输入分值"  />
                </div>
              </div>
              <div style="float: left;width: 50px">
                <a @click="openSample(!addType ? 'questionBank_options_common':'questionBank_options_recombination')" style="width: 50px;">示例图</a>
              </div>
            </div>

          </a-form-model-item>
          <a-form-model-item label="解析" >
            <div style="display: flex">
              <a-textarea v-model="addForm.parse" />
              <a @click="openSample(!addType ? 'questionBank_parse_common':'questionBank_parse_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item label="题干" prop="stemLog"  v-if="addType===true">
            <div style="display: grid">
              <div style="display: flex">
                <a-button type="primary" icon="search" style="margin-bottom: 5px;width: 75px" @click="()=>{this.addStem=true;this.getStem(1,10)}">搜索</a-button>
                <a @click="openSample(!addType ? 'questionBank_stemLog_common':'questionBank_stemLog_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
              </div>
              <span>{{addForm.stemLog}}</span>
            </div>
          </a-form-model-item>

          <!--        <a-form-model-item label="标签" prop="able"  v-if="addType===true">-->
          <!--          <a-input  v-model="addForm.able"  placeholder="请输入"  />-->
          <!--        </a-form-model-item> -->
          <a-form-model-item label="标签" prop="able" >
            <div style="display: flex">
              <a-input  v-model="addForm.able"  placeholder="请输入"  />
              <a @click="openSample(!addType ? 'questionBank_able_common':'questionBank_able_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>


        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="addClose">
            取消
          </a-button>
          <a-button type="primary" @click="addOk">
            确认
          </a-button>
        </div>
      </a-drawer>

      <a-drawer
          :get-container="false"
          title="修改"
          width="50%"
          :visible="editShow"
          @close="()=>{this.editShow = false;this.editType=false;this.typeInfoModel = [];this.modelPagination.title = '';this.modelPagination.pageNo = 1}"
      >
        <a-form-model style="margin-bottom: 40px" :rules="rules" :model="editForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editItem" >
          <a-form-model-item label="类型" prop="type">
            <a-radio-group @change="()=>{
           if(editForm.type === 'radio'){
             this.editForm.able = '单选题'
           }else {
             this.editForm.able = '多选题'
           }
         }" v-model="editForm.type">
              <a-radio value="radio" >
                单选
              </a-radio>
              <a-radio value= "checkbox">
                多选
              </a-radio>
            </a-radio-group>
            <a @click="openSample(!editType ? 'questionBank_type_common':'questionBank_type_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
          </a-form-model-item>
          <a-form-model-item label="分类" >
            <!--          <a-select-->
            <!--              placeholder="选择分类"-->
            <!--              v-model="editForm.questionCode"-->
            <!--              mode="multiple"-->
            <!--              :maxTagCount= 3-->
            <!--              showArrow-->
            <!--              :maxTagTextLength = 7-->
            <!--          >-->
            <!--            <a-select-option v-for="item in typeInfoModel" :key="item.id" :value="item.code">-->
            <!--              {{item.name}}-->
            <!--            </a-select-option>-->
            <!--          </a-select>-->

            <a-select
                placeholder="选择分类"
                v-model="editForm.questionCode"
                mode="multiple"
                :maxTagCount= 3
                showArrow
                :maxTagTextLength = 7
                show-search
                option-filter-prop="children"
                @popupScroll="popupScroll_questionCode"
                @search="select_questionCode"
            ><a-select-option v-for="item in typeInfoModel" :key="item.id" :value="item.code">
              {{item.name}}
            </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="题目名称" prop="name">
            <div style="display: flex">
              <a-input  v-model="editForm.name"  placeholder="输入名称" @change="editTitle" />
              <a @click="openSample(!editType ? 'questionBank_name_common':'questionBank_name_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
            <ul  class="titleList" v-for="item in titleList">
              {{item.name}}
            </ul>
          </a-form-model-item>
          <a-form-model-item label="选项" prop="options">
            <div style="width: 100%;display: flex">
              <div style="width: 90%;">
                <div style="display: flex;align-items: center"  v-for="(item,index) in editForm.options" :key="index">
                  <a-input placeholder="输入选项" style="width: 220px;margin-right: 10px" v-model="item.title" />
                  <a-icon type="plus-circle" v-if="index <=0" style="cursor: pointer;font-size: 18px;color: #1890ff;margin-right: 10px"  @click="editOptions" />
                  <a-icon type="minus-circle" v-if="index!==0" style="cursor: pointer;font-size: 18px;margin-right: 10px"   @click="delEditOptions(item,index)" />
                  <input  type="checkbox" v-model="item.answer" v-if="editForm.type==='checkbox'" :id="'check'+index" @change="resultKeyEdit(item,index)">
                  <input  type="radio" name="radio" :value="item.answer" v-if="editForm.type==='radio'" :id="'radio'+index" @change="resultRadioEdit(item,index)">
                  正确答案
                  <a-input-number v-show="editType" v-if="item.answer===1 " v-model="item.socore" style="margin-left: 5px;flex: 1" placeholder="请输入分值"  />
                </div>
              </div>
              <div style="float: left;width: 50px">
                <a @click="openSample(!editType ? 'questionBank_options_common':'questionBank_options_recombination')" style="width: 50px;">示例图</a>
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="解析" >
            <div style="display: flex">
              <a-textarea v-model="editForm.parse" />
              <a @click="openSample(!editType ? 'questionBank_parse_common':'questionBank_parse_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
          <a-form-model-item label="题干" prop="stemId"  v-if="editType===true">
            <div style="display: grid">
              <div style="display: flex">
                <a-button type="primary" icon="search" style="margin-bottom: 5px;width: 75px" @click="()=>{this.addStem=true;this.getStem(1,10)}">搜索</a-button>
                <a @click="openSample(!editType ? 'questionBank_stemLog_common':'questionBank_stemLog_recombination')" style="width: 50px;margin-left: 3px">示例图</a>
              </div>
              <span>{{editForm.stemLog}}</span>
            </div>
          </a-form-model-item>
          <!--        <a-form-model-item label="标签" prop="able"  v-if="editType===true">-->
          <!--          <a-input  v-model="editForm.able"   placeholder="请输入"  />-->
          <!--        </a-form-model-item>-->
          <a-form-model-item label="标签" prop="able">
            <div style="display: flex">
              <a-input  v-model="editForm.able"   placeholder="请输入"  />
              <a @click="openSample(!editType ? 'questionBank_able_common':'questionBank_able_recombination')" style="width: 50px;margin-top: -5px;margin-left: 3px">示例图</a>
            </div>
          </a-form-model-item>
        </a-form-model>

        <div
            :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
        >
          <a-button style="marginRight: 8px" @click="()=>{this.editShow = false;this.editType=false;this.typeInfoModel = [];this.modelPagination.title = '';this.modelPagination.pageNo = 1}">
            取消
          </a-button>
          <a-button type="primary" @click="editOk">
            确认
          </a-button>
        </div>
      </a-drawer>

      <a-drawer
          title="选择题干"
          width="50%"
          style="z-index: 1001"
          :visible="addStem"
          @close="()=>{this.addStem = false;this.selectedRowKeys=[]}"
      >

        <div style="display: flex;justify-content: space-between">
          <a-input-search placeholder="请输入" style="width: 60%;margin-bottom: 5px" enter-button="搜索"   @search="stemSearch" />
          <a-button style="margin-bottom: 5px" type="primary" @click="stemAdd">新增题干</a-button>
        </div>

        <a-table  bordered :columns="stemColumns"  :data-source="stemTable" :pagination="false" rowKey="id"
                  :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,type: 'radio'}"
        >
        <span slot="name" slot-scope="text,item">
          <span>{{text.name}}</span>
          <span v-if="text.name===''">
            <a-textarea placeholder="请输入" style="width: 80%;margin-right: 5px"  v-model="stemValue"   @keyup.enter="addStemOk" /> <a-icon type="check" class="addStem" style="cursor:pointer"  @click="addStemOk"/>
          </span>
        </span>
        </a-table>
        <div style="margin-top: 10px;align-items: center;display: flex;justify-content: space-between">
          <span style="font-size: medium;color: #929292">共 {{stemPagination.total}} 条记录 第 {{ stemPagination.current }} / {{ Math.ceil(stemPagination.total / stemPagination.pageSize) }} 页</span>
          <a-pagination
              :page-size-options="stemPagination.sizeOptions"
              v-model="stemPagination.current"
              :total="stemPagination.total"
              show-size-changer
              :page-size="stemPagination.pageSize"
              @change="stemPage"
              @showSizeChange="stemPage"
              show-quick-jumper
              style="float: right"
          >
          </a-pagination>
        </div>
      </a-drawer>

      <a-modal destroyOnClose v-model="typeAdd" title="分类 — 新增" @ok="typeAddOk" @cancel="()=>{this.typeAdd = false}">
        <a-form-model  :rules="rules"  :model="typeForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="addType" >
          <a-form-model-item  label="名称" prop="name">
            <a-input  v-model="typeForm.name"/>
          </a-form-model-item>
          <!--        <a-form-model-item label="code" prop="code">-->
          <!--          <a-input  v-model="typeForm.code"/>-->
          <!--        </a-form-model-item>-->
          <a-form-model-item label="模块" prop="board">
            <a-select placeholder="请选择" :defaultValue="typeForm.board"  @change="typeChange($event, 'typeForm')">
              <a-select-option v-for="item in headerModeList" :value="item.code">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="板块" prop="examTypeId">
            <a-select placeholder="请选择" :defaultValue="typeForm.examTypeId" @change="examChange($event, 'typeForm')">
              <a-select-option v-for="item in headerList" :value="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-modal v-model="typeEdit" title="分类 — 修改" @ok="typeEditOk">
        <a-form-model  :rules="rules"  :model="typeEditForm" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editType" >
          <a-form-model-item  label="名称" prop="name">
            <a-input  v-model="typeEditForm.name"/>
          </a-form-model-item>
          <a-form-model-item label="模块" prop="board">
            <a-select placeholder="请选择" :defaultValue="typeEditForm.board"  @change="typeChange($event, 'typeEditForm')">
              <a-select-option v-for="item in headerModeList" :value="item.code">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="板块" prop="examTypeId">
            <a-select placeholder="请选择" :defaultValue="typeEditForm.examTypeId" @change="examChange($event, 'typeEditForm')">
              <a-select-option v-for="item in headerList" :value="item.id">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--        <a-form-model-item label="code" prop="code">-->
          <!--          <a-input  v-model="typeEditForm.code"/>-->
          <!--        </a-form-model-item>-->
        </a-form-model>
      </a-modal>
      <PublicSample :propList="propList"></PublicSample>
    </a-spin>
  </div>
</template>

<script>

import {
  delTitleExam,
  getExamLeft,
  getTitleId,
  getTitleList,
  getTitleStem,
  postExam,
  postQuestion,
  postStem,
  postTitleExam,
  postTitleStem,
  postTitleType,
  putTitleExam,
  putTitleStem,
  putTitleType,
  getExamList,
  getMoKuaiData,
  getExamLeftNew,
  postTitleTypeNew,
  putTitleTypeNew
} from "@/service/titleExam_api";
import {update_File} from "@/utils/update";
import axios from "axios";
import PublicSample from "@/utils/PublicSample";


export default {
  name: "questionBank",
  components: {PublicSample},
  data(){
    return{
      propList:{
        visible:false,
        code:null,
      },
      value: undefined,
      typeShow:false,
      addShow:false,
      addType:false,
      addStem:false,
      editShow:false,
      editType:false,
      spinning:false,
      typeAdd:false,
      typeEdit:false,
      stemValue:'',
      typeEditForm:{},
      typeForm:{},
      editForm:{},
      addForm:{
        options:[
          {
            answer:0,
            title:'',
          }
        ],
        questionCode:[],
        parse:''
      },
      leftPagination:{
        total:0,
        pageNo: 1,
        pageSize:10,
      },
      modelPagination:{
        pageNo: 1,
        title:''
      },
      typeInfoModel:[],
      pagination:{
        sizeOptions: ['10','30', '50'],
        total:0,
        current: 1,
        pageSize:10,
      },
      stemPagination:{
        sizeOptions:['10','30', '50'],
        total:0,
        current: 1,
        pageSize:10,
      },
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      labelCol: { span: 4},
      wrapperCol: { span: 16 },
      searchName:'',
      rules: {
        examTypeId:[{ required: true, message: '请选择', trigger: 'blur' }],
        board:[{ required: true, message: '请选择', trigger: 'blur' }],
        name:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        code:[{ required: true, message: '请输入code值', trigger: 'blur' }],
        type:[{ required: true, message: '请选择类型', trigger: 'change' }],
        options:[{required:true,message:'请输入选项',trigger: 'blur'}],
        titleExam:[{ required: true, message: '请输入名称', trigger: 'blur' }],
        parse:[{ required: true, message: '请输入解析', trigger: 'blur' }],
        questionCode:[{ required: true, message: '请选择分类', trigger: 'blur' }],
        able:[{ required: true, message: '请输入标签', trigger: 'blur' }],
        stemLog:[{ required: true, message: '请选择题干', trigger: 'change'}],
        stemId:[{ required: true, message: '请选择题干', trigger: 'change'}],
      },
      columns:[
        {
          title: '题目名称',

          scopedSlots: { customRender: 'name' },
          // dataIndex: 'name',
        },
        {
          title: '所属分类',
          dataIndex: 'typeName',
          // scopedSlots: { customRender: 'code' },
        },
        {
          title: '类型',
          width:'10%',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '题型',
          scopedSlots: { customRender: 'stemId' },
        },
        {
          title: '标签',
          dataIndex: 'able',
        },
        {
          title: '操作',
          fixed: 'right',
          scopedSlots: {customRender: 'operate'},
        },
      ],
      stemColumns:[
        {
          title: '题干名称',
          scopedSlots: {customRender: 'name'},
        },
      ],
      selectedRowKeys: [],
      stemTable:[],
      InfoTable:[],
      typeInfo:[],
      menu:[0],
      titleList:[],
      titleShow:false,
      menuIndex:0,
      formData:null,
      headerSpin: false,
      headerList: [],
      examTypeId: '',
      headerModeList: [],
      boardCode: '',
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"题库管理")
    const user_info = window.localStorage.getItem("user_info");
    this.user_name =  JSON.parse(user_info).user_name
  },
  async mounted() {
    // this.getTitleExam('',1,10)
    await this.getHeaderData()
    // await this.getTitleExam()
    // await this.getExamList()
  },
  methods:{
    typeChange (e, type) {
      this[type].board = e
    },
    examChange (e, type) {
      this[type].examTypeId = e
    },
    async getHeaderData () {
      const data = {
        page: 1,
        pageSize: 10000
      }
      const res = await getMoKuaiData(data)
      if (res.code === 200) {
        this.headerList = res.data
        this.headerModeList = [
          {
            name: '同步练习',
            code: 'EXE'
          },
          {
            name: '模拟考试',
            code: 'MOCK'
          },
          {
            name: '历年真题',
            code: 'PAST'
          }
        ]
        this.examTypeId = res.data[0].id
        this.boardCode = this.headerModeList[0].code
        await this.getTitleExam()
      }
    },
    //获取左侧一级列表
    async getTitleExam(isMenu, status){
      this.spinning =true
      let data = {
        page:this.leftPagination.pageNo,
        pageSize:this.leftPagination.pageSize,
        examTypeId: this.examTypeId,
        board: this.boardCode,
      }
      if (status) {
        data = {
          page:1,
          pageSize:this.leftPagination.pageSize,
          examTypeId: this.examTypeId,
          board: this.boardCode,
        }
      }
      const exam = await getExamLeftNew(data)
      if (exam.code === 200){
        this.typeInfo = exam.data
        if (exam.data.length > 0) {
          if(!isMenu){
            this.menu[0] = exam.data[0].id
            this.typeCode=exam.data[0].code
          }
          this.leftPagination.total = exam.count
        } else {
          this.menu[0] = ''
          this.leftPagination = {
            total:0,
            pageNo: 1,
            pageSize:10,
          }
          this.pagination={
            sizeOptions: ['10','30', '50'],
            total:0,
            current: 1,
            pageSize:10,
          }
        }
        await this.getExamList()
      }else {
        this.$message.error('接口获取出错' + exam.message)
      }
      this.spinning =false
    },
    //分类用于对话框
    async getExamModel(code){
      let data = {
        title:this.modelPagination.title,
        page_no:this.modelPagination.pageNo,
         code:code ?code:''  //下拉框回显
      }
      const exam = await getExamLeft(data)
      if (exam.code === 0 && exam.data.length){
        let list = exam.data
        for (let i=0;i<list.length;i++){
          this.typeInfoModel.push({
            id: list[i].id,
            code: list[i].code,
            name: list[i].name,
          })
        }
      }
    },

    // 获取二级列表
    async getExamList(){
      let data = {
        name:this.searchName,
        id:this.menu[0],
        page_no:this.pagination.current,
        page_size:this.pagination.pageSize,
      }
      this.spinning =true
      const exam = await getExamList(data)
      if (exam.code === 0){
        this.InfoTable = exam.data
        this.pagination.total = exam.count
      }else {
        this.$message.error('接口获取出错' + exam.message)
      }
      this.spinning =false
    },
    //分页跳转
    tableChange(current, pageSize){
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.getExamList()
    },
    //选择题干
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      if (this.addShow===true){
        this.addForm.stemId=Number(this.selectedRowKeys.toString())
        let stem = this.stemTable.find(v => {
          return v.id === this.addForm.stemId
        })
        this.addForm.stemLog=stem.name
      }
      if (this.editShow===true){
        this.editForm.stemId=Number(this.selectedRowKeys.toString())
        let stem = this.stemTable.find(v => {
          return v.id === this.editForm.stemId
        })
        this.editForm.stemLog=stem.name
      }

    },
    //获取题干
    async getStem(pageNum,pageSize,name,id){
      const info = await getTitleStem(pageNum,pageSize,name,id)
      if (info.code===0){
        this.stemTable=info.data.content
        this.stemPagination.total=info.data.count
      }
    },
    //题干addBtn
    stemAdd(){
      if (this.stemTable[this.stemTable.length - 1].name!==''){
        this.stemTable.push({
          id:this.stemTable.length+1,
          name:''
        })
      }else {
        this.$message.warning('请完善上一项并提交再新增')
      }
    },
    //新增题干
    async addStemOk(){
      if (this.stemValue!=='' && this.stemValue!==null){
        const data={
          name:this.stemValue
        }
        const add = await  postStem(data)
        if (add.code===0){
          this.getStem(this.stemPagination.current,this.stemPagination.pageSize)
          this.stemValue=''
          this.$message.success('新增成功')
        }
      }else {
        this.$message.error("输入有空~")
      }
    },
    //题干搜索
    stemSearch(value){
      this.stemName=value
      this.getStem(1,this.stemPagination.pageSize,value)
    },
    stemPage(current, pageSize){
      this.stemPagination.current = current
      this.stemPagination.pageSize = pageSize
      this.getStem(current, pageSize,this.stemName)
    },
    //新增选项
    addOptions(){
      let ifTrue = true
      this.addForm.options.forEach(item => {
        if (item.title === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.addForm.options.push({
          answer:0,
          title:''
        })}
    },
    async addTitle(e){
      if (e.target.value !== '' & e.target.value !== undefined && e.target.value !== null){
        const search =await getTitleList(e.target.value)
        this.titleList = search.data
        let arr = []
        arr.push({
          name: e.target.value
        })
        for(let i = 0; i < this.titleList.length; i++) {
          for(let j = 0; j < arr.length; j++) {
            if(arr[j].name === this.titleList[i].name){
              this.$message.error('题目名称重复，重新输入')
            }}}
      }else {
        this.titleList = []
      }
    },
    //修改题目名称对比
    async editTitle(e) {
      if (e.target.value !== '' & e.target.value !== undefined && e.target.value !== null){
        const search =await getTitleList(e.target.value)
        this.titleList = search.data
        let arr = []
        arr.push({
          name: e.target.value
        })
        for(let i = 0; i < this.titleList.length; i++) {
          for(let j = 0; j < arr.length; j++) {
            if(arr[j].name === this.titleList[i].name){
              this.$message.error('题目名称重复，重新输入')
            }}}
      }else {
        this.titleList = []
      }
    },
    //滚动条监听
    popupScroll_questionCode(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2){
        this.modelPagination.pageNo++
        this.getExamModel()
      }
    },
    select_questionCode(value) {
      // //先清空，防止鼠标滑动时添加的数据输入时添加的数据重复
      this.typeInfoModel = []
      this.modelPagination.title = value
      this.modelPagination.pageNo = 1
      this.getExamModel()
    },
    show() {
      this.typeShow = true;
    },
    hide() {
      this.typeShow = false;
    },
    typeEditBtn(row){
      this.typeEditForm = {...row}
      this.typeEditId = row.id
      this.typeEdit = true
    },
    //修改选项添加
    editOptions(){
      let ifTrue = true
      this.editForm.options.forEach(item => {
        if (item.title === '') {
          this.$message.warning('请完善选项')
          ifTrue = false
        }})
      if (ifTrue) {
        this.editForm.options.push({
          answer:0,
          title:'',
        })}
    },
    delEditOptions(item,index){
      this.editForm.options.splice(index, 1)
    },
    delOptions(item,index){
      this.addForm.options.splice(index, 1)
    },

    resultKey(row,index){
      let all = document.getElementById("check"+index);
      if (all.checked===true){
        row.answer = 1
      }else {
        row.answer = 0
      }
    },
    resultRadio(row,index){
      row.answer = 1
      this.addForm.options.forEach((value,subIndex)=>{
        if (subIndex!==index){
          value.answer=0
        }
      })
    },
    //修改是否显示
    resultKeyEdit(row,index){
      let all = document.getElementById("check"+index);
      if (all.checked===true){
        row.answer = 1
      }else {
        row.answer = 0
      }
    },
    resultRadioEdit(row,index){
      row.answer = 1
      this.editForm.options.forEach((value,subIndex)=>{
        if (subIndex!==index){
          value.answer=0
        }
      })
    },
    //新增题目分类
    async typeAddOk(){
      this.$refs.addType.validate(async valid =>{
        if(valid){
          const user_info = window.localStorage.getItem("user_info");
          const createdByName =  JSON.parse(user_info).user_name
          const data = {
            ...this.typeForm,
            createdByName
          }
          console.log(data)
          const add =await postTitleTypeNew(data)
          if (add.code === 200){
            await this.loadBtn()
            await this.getTitleExam()
            // await this.getExamList()
            this.$message.success('添加成功')
            this.$refs.addType.resetFields();
            this.typeAdd = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //新增修改一级分类后刷新
    loadBtn() {
      this.leftPagination.pageNo = 1
      this.leftPagination.pageSize = 10
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.searchName = ''
    },
    async typeEditOk(){
      this.$refs.editType.validate(async valid =>{
        if(valid){
          const user_info = window.localStorage.getItem("user_info");
          const createdByName =  JSON.parse(user_info).user_name
          const data = {
            ...this.typeEditForm,
            createdByName
          }
          const edit =await putTitleTypeNew(data)
          if (edit.code === 200){
            //传ture是不更新menu的值
            await this.getTitleExam(true)
            await this.getExamList()
            this.$message.success('修改成功')
            this.$refs.editType.resetFields();
            this.typeEdit = false
            this.typeShow = false
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //左侧身份选择
    leftChange(value){
      //点击分类回复
      this.pagination.current = 1
      this.pagination.pageSize = 10
      const row = this.typeInfo.find(option => option.id ===  value.key)
      this.typeCode=row.code
      this.menu[0] = value.key
      this.getExamList()
    },
    //搜索
    searchOk(){
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getExamList()
    },
    //搜索重置
    searchReset(){
      this.searchName = ''
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getExamList()
    },
    async add(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          let arr =[]
          let add
          const form=this.addForm
          if (this.addType===false){
            for (let i=0;i<this.addForm.options.length;i++){
              arr.push({
                title:this.addForm.options[i].title,
                answer:this.addForm.options[i].answer,
                id:i
              })
            }
            const data={
              name:form.name,
              options:arr,
              parse:form.parse,
              questionCode:form.questionCode,
              type:form.type,
              able:form.able,
            }
            add = await postTitleExam(this.user_name,data)
          }else {
            for (let i=0;i<this.addForm.options.length;i++){
              arr.push({
                title:this.addForm.options[i].title,
                answer:this.addForm.options[i].answer,
                id:i,
              })
              if (this.addForm.options[i].answer===1){
                arr[i].socore=this.addForm.options[i].socore
              }
            }
            const data={
              name:form.name,
              options:arr,
              parse:form.parse,
              questionCode:form.questionCode,
              type:form.type,
              able:form.able,
              stemId:form.stemId
            }
            add = await postTitleStem(this.user_name,data)
          }

          if (add.code === 0){
            //获取二级题目列表
            await this.getExamList()
            this.$message.success('新增成功')
            this.$refs.addItem.resetFields();
            this.addForm={
              options:[
                {
                  answer:0,
                  title:''
                }
              ],
              questionCode:[],
              parse:''
            }
            this.addShow = false
            this.typeInfoModel = []
            this.modelPagination.title = ''
            this.modelPagination.pageNo = 1
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //新增确认
    addOk(){
      for(let i = 0; i < this.addForm.options.length; i++) {
        if (this.addForm.options[i].answer === 1 ){
          this.add()
          return true;
        }
      }
      this.$message.error('未选择正确答案')
      return false;

    },
    addClose(){
      this.addShow = false;
      this.typeInfoModel = []
      this.modelPagination.title = ''
      this.modelPagination.pageNo = 1
      this.addType=false;
      this.$refs.addItem.resetFields();
      this.addForm.questionCode=[];
      this.addForm.parse=''
      this.addForm.options = [{
        answer:0,
        title:'',
      }]
    },
    //修改Btn
    async editBtn(id){
      this.editId = id
      const edit =await getTitleId(id)
      if (edit.code === 0){
        this.editForm = edit.data
        this.editForm.questionCode.forEach(item =>{
            this.getExamModel(item)  //查询回显数据插入分类option列表
          })
        if (this.editForm.hasOwnProperty('stemId')){
          this.editType=true
          await this.getStem(1, 10, '', this.editForm.stemId)
          this.editForm.stemLog=this.stemTable[0].name
        }

        this.editShow = true
        await this.getExamModel()
        //单选题判断正确答案赋值
        setTimeout(() => {
          if (this.editForm.type==='radio'){
            let arr = document.getElementsByName("radio")
            arr.forEach((row,index)=>{
              if (row.value==='1'){
                row.checked=true
              }
            })

          }
        },100);

      }
    },
    async edit(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          let arr =[]
          let edit
          const form=this.editForm
          if (this.editForm===false){
            for (let i=0;i<this.editForm.options.length;i++){
              arr.push({
                title:this.editForm.options[i].title,
                answer:this.editForm.options[i].answer,
                id:i
              })
            }
            const data={
              name:form.name,
              options:arr,
              parse:form.parse,
              questionCode:form.questionCode,
              type:form.type,
              able:form.able,
            }
            edit =await putTitleExam(this.editId,data)
          }else {
            for (let i=0;i<this.editForm.options.length;i++){
              arr.push({
                title:this.editForm.options[i].title,
                answer:this.editForm.options[i].answer,
                id:i,
              })
              if (this.editForm.options[i].answer===1){
                arr[i].socore=this.editForm.options[i].socore
              }
            }
            const data={
              name:form.name,
              options:arr,
              parse:form.parse,
              questionCode:form.questionCode,
              type:form.type,
              able:form.able,
              stemId:form.stemId
            }
            edit =await putTitleStem(this.editId,data)
          }
          if (edit.code === 0){
            //刷新
            await this.getExamList()
            this.$message.success('修改成功')
            this.$refs.editItem.resetFields();
            this.editForm = {}
            this.editShow = false
            this.typeInfoModel = []
            this.modelPagination.title = ''
            this.modelPagination.pageNo = 1
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    //修改
    editOk(){
      for(let i = 0; i < this.editForm.options.length; i++) {
        if (this.editForm.options[i].answer === 1 ){
          this.edit()
          return true;
        }
      }
      this.$message.error('未选择正确答案')
      return  false;
    },
    async delTitle(id){
      const Info =await delTitleExam(id)
      if (Info.code === 0){
        await this.getExamList()
        this.$message.success('删除成功')
      }else {
        this.$message.error('删除失败')
      }
    },
    cancel(){
      this.$message.warning('取消删除');
    },
    //左侧分页
    async pageChange(pageNumber) {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.leftPagination.pageNo = pageNumber
      await this.getTitleExam()
      await this.getExamList()
    },
    loadBtns(){
      window.open('https://yun-new-admin-1252497858.cos.ap-guangzhou.myqcloud.com/file/ddb677da-35f0-4f3f-9ccd-2ecd2915bd4e.xlsx')
    },
    toImport(){
      //获取隐藏的input
      const inputFile = document.querySelector("input[type='file']");
      //触发其点击事件, 弹出文件选择
      inputFile.click();
    },
    bulkImport(e) {
      let inputDOM = e.target.files[0];
      let formData = new FormData()
      formData.append('exclFile', inputDOM)
      this.question(formData)
    },
    async question(formData) {
      let byName = (JSON.parse(localStorage.getItem('user_info'))).user_name
      const response = await postQuestion(formData,byName,this.typeCode)
      if(response.statuscode === 200){
        this.$message.success("导入成功~")
        //传ture是不更新menu的值
        this.searchName = ''
        this.pagination.current = 1
        this.pagination.pageSize = 10
        await this.getTitleExam(true)
        await this.getExamList()
      }else if(response.statuscode === 504){
        this.$message.error(response.data)
      }else if(response.statuscode === 502){
        this.$message.error(response.data)
      }else {
        this.$message.warning(response.message)
      }
      //清空
      document.getElementById('upFileId').value = null;
    },
    headerClick (index, id, type) {
      // const spanList = type === 'mode' ? this.$refs.boardSpan : this.$refs.headerSpan
      // for (let i = 0; i < spanList.length; i++) {
      //   spanList[i].className = 'selectNoFont'
      // }
      // spanList[index].className = 'selectFont'
      if (type === 'mode') this.boardCode = id
      else this.examTypeId = id
      this.getTitleExam(false, true)
    },
    openSample(code){
      // code加上时间防止重复
      this.propList = {
        visible:true,
        code:code+'202303221012',
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.titleList{
  padding: 0;
  margin: 0;
}
.header {
  padding-top: 30px;
  padding-left: 30px;
  background-color: white;
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 15px;
}
.header-div {
  display: flex;
  flex-wrap: wrap;
}

.header-div > span{
  cursor: pointer;
  //width: 150px;
  //height: 40px;
  padding: 10px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 15px;
  transition: all 0.2s;
}
.selectNoFont {
  background-color: white;
  color: black;
}
.selectNoFont:hover {
  background-color: #E6F7FF;
  color: #18ACFF;
}
.selectFont {
  background-color: #1890FF;
  color: white;
}
.addStem :hover{
  color: #108ee9;
  font-size: medium;
}
.questionBank{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .menu_select{
    background-color: white;
    width: 18%;
    height:100%;
    overflow:hidden;
    word-break: break-all;
    padding-bottom:9999px;
    margin-bottom:-9999px;
    float:right;
  }
  .InfoTable{
    width: 81%;
    height:auto;
    background-color: white;
    word-break: break-all;
    float:right;
    .select_Info{
      display: flex;
      height: 60px;
      padding: 10px;
      align-items: center;
      background-color: white;
    }
    .table_Info{
      padding: 10px;
      min-height: 720px;
      background-color: white;
    }
  }
}
</style>
